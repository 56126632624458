// 设备信息默认图片
<template>
    <div class='customerInfoManage baseBg'
         style="height: auto;min-height:100%;">
        <el-form class=""
                 v-loading="loading"
                 :gutter="20">
            <el-row :gutter="20">
                <el-col :span="12"
                        v-for="(item,index) in moduleList"
                        :key="index"
                        class="">
                    <el-form-item>
                        <comFileUploadList :form="{title:item.name}"
                                           fileBoxHeight="500px"
                                           :showNumber="true"
                                           :uploadData="{resourceType:item.value,isTemplate:true}"
                                           :showBlock="true"
                                           :neddLoadFile="true"
                                           :showUpBtn="true"></comFileUploadList>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
// import baseManageApi from "@/api/baseManageApi.js";
import comFileUploadList from "@/components/upload/comFileUploadList"; //文件上传
// import commonAPI from "@/api/commonAPI.js";
export default {
    name: "comDefaultDeviceImg",
    components: {
        comFileUploadList,
    },
    props: {
        module: {
            type: String,
            default: "",
        },
    },
    watch: {},
    data() {
        return {
            loading: false,
            form: {
                title: "",
            },
            moduleList: [
                {
                    value: "empManagerLogo",
                    name: "设备默认图片",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
    computed: {},
};
</script>
<style scoped>
</style>